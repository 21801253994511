import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Lofginform.css';

function Lofginform() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [user, setUser] = useState(null); // Добавляем состояние для хранения данных пользователя
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      fetchUserInfo(token); // Если токен есть, получаем информацию о пользователе
    }
  }, [navigate]);

  // Функция для получения данных пользователя
  const fetchUserInfo = async (token) => {
    try {
      const response = await fetch('https://nukesul-backend-1bde.twc1.net/api/user', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Не удалось получить данные пользователя');
      }
      const userData = await response.json();
      setUser(userData); // Сохраняем данные пользователя в состоянии
    } catch (error) {
      console.error('Ошибка при получении данных пользователя:', error);
    }
  };

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://nukesul-backend-1bde.twc1.net/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        alert(errorData.message || 'Ошибка входа');
        return;
      }

      const data = await response.json();

      if (!data.token || !data.userId) {
        alert('Отсутствует токен или userId в ответе');
        return;
      }

      localStorage.setItem('token', data.token);
      localStorage.setItem('userId', data.userId);
      localStorage.setItem('email', email);
      navigate('/'); // Перенаправляем на главную страницу после входа
      fetchUserInfo(data.token); // Получаем информацию о пользователе после входа
    } catch (error) {
      alert('Произошла ошибка. Попробуйте еще раз.');
    }
  };

  return (
    <div className="lf-background-container">
      <div className="lf-form-container">
        <h1 className="lf-title">Вход</h1>
        <div className="lf-card">
          <form onSubmit={handleSubmit}>
            <div className="lf-form-group">
              <label className="lf-label" htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                className="lf-input-field"
                value={email}
                onChange={handleEmailChange}
                required
              />
            </div>
            <div className="lf-form-group">
              <label className="lf-label" htmlFor="password">Пароль</label>
              <input
                type="password"
                id="password"
                className="lf-input-field"
                value={password}
                onChange={handlePasswordChange}
                required
              />
            </div>
            <div className="lf-button-group">
              <button type="submit" className="lf-button">Войти</button>
            </div>
          </form>
          <div className="lf-register-link">
            <p>Нет аккаунта? <Link to="/register">Регистрация</Link></p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Lofginform;
