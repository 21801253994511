import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../styles/OrderPage.css";

const OrderPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const initialCartItems = location.state?.cartItems || [];
  const [cartItems, setCartItems] = useState(initialCartItems);
  const [isOrderSection, setIsOrderSection] = useState(false);
  const [orderDetails, setOrderDetails] = useState({ name: "", phone: "", comments: "" });
  const [deliveryDetails, setDeliveryDetails] = useState({ name: "", phone: "", address: "", comments: "" });
  const [isOrderSent, setIsOrderSent] = useState(false);

  const [promoCode, setPromoCode] = useState("");
  const [discount, setDiscount] = useState(0);

  useEffect(() => {
    const checkUserAuthentication = async () => {
      const token = localStorage.getItem("token");
      const userId = localStorage.getItem("userId");

      if (!token || !userId) {
        console.error("User is not authenticated. Please log in.");
        setIsLoggedIn(false);
        return;
      }

      try {
        const response = await fetch(`https://nukesul-backend-1bde.twc1.net/api/authenticate`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ user_id: userId }),
        });

        if (response.ok) {
          console.log("User is authenticated.");
          setIsLoggedIn(true);
        } else {
          console.error("Authentication failed.");
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error("Authentication error:", error);
        setIsLoggedIn(false);
      }
    };

    checkUserAuthentication();
  }, []);

  const calculateDiscountedPrice = (price) => {
    const validPrice = price || 0; // Убедиться, что цена не undefined
    return validPrice * (1 - discount / 100);
  };
  const calculateTotal = () => {
    const total = cartItems.reduce((sum, item) => {
      const price = item.price || 0;
      return sum + price * item.quantity;
    }, 0);
    const discountedTotal = total * (1 - discount / 100);
    return { 
      total: total.toFixed(2), 
      discountedTotal: discountedTotal.toFixed(2) 
    };
  };
  
  

  const handleQuantityChange = (itemId, change) => {
    setCartItems((prevItems) => {
      return prevItems.map((item) => {
        if (item.id === itemId) {
          return { ...item, quantity: Math.max(item.quantity + change, 1) };
        }
        return item;
      });
    });
  };

  const handleOrderChange = (e) => {
    const { name, value } = e.target;
    setOrderDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  const handleDeliveryChange = (e) => {
    const { name, value } = e.target;
    setDeliveryDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  const handlePromoCodeSubmit = async () => {
    try {
      const response = await fetch("https://nukesul-backend-1bde.twc1.net/api/validate-promo", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ promoCode }),
      });

      if (response.ok) {
        const data = await response.json();
        setDiscount(data.discount);
        alert(`Промокод применен! Скидка ${data.discount}% добавлена.`);
      } else {
        const errorData = await response.json();
        alert(errorData.message || "Неверный промокод.");
      }
    } catch (error) {
      console.error("Ошибка проверки промокода:", error);
      alert("Ошибка проверки промокода.");
    }
  };
  const validateFields = () => {
    if (isOrderSection) {
      if (!orderDetails.name || !orderDetails.phone) {
        alert("Пожалуйста, заполните имя и телефон для заказа с собой.");
        return false;
      }
    } else {
      if (!deliveryDetails.name || !deliveryDetails.phone || !deliveryDetails.address) {
        alert("Пожалуйста, заполните имя, телефон и адрес для доставки.");
        return false;
      }
    }
    return true;
  };
  const sendOrderToServer = async () => {
    if (!validateFields()) return; // Проверка обязательных полей
    try {
      const cartItemsWithPrices = cartItems.map((item) => ({
        id: item.id,
        name: item.name,
        quantity: item.quantity,
        originalPrice: item.price || 0,
        discountedPrice: calculateDiscountedPrice(item.price || 0),
      }));
  
      const response = await fetch("https://nukesul-backend-1bde.twc1.net/api/send-order", {
        method: "POST",  // Используем POST вместо GET
        headers: {
          "Content-Type": "application/json",  // Указываем, что данные в формате JSON
        },
        body: JSON.stringify({
          orderDetails,
          deliveryDetails,
          cartItems: cartItemsWithPrices,
          discount,
          promoCode,
        }),
      });
  
      if (response.ok) {
        setIsOrderSent(true);
        setCartItems([]);
        setTimeout(() => {
          setIsOrderSent(false);
          navigate("/");
        }, 4000);
      } else {
        alert("Ошибка при отправке заказа.");
      }
    } catch (error) {
      console.error("Ошибка:", error);
      alert("Ошибка при отправке заказа.");
    }
  };
  

  return (
    <div className="order-page">
      <div className="button-group">
        <button className="button_buy" onClick={() => setIsOrderSection(false)}>
          Доставка
        </button>
        <button className="button_buy" onClick={() => setIsOrderSection(true)}>
          С собой
        </button>
      </div>

      <div className="items-section">
        {cartItems.map((item) => {
          const price = item.price || 0; // Подстановка 0, если цена undefined
          const discountedPrice = calculateDiscountedPrice(price).toFixed(2);

          return (
            <div key={item.id} className="order-item">
              <img
                src={`https://nukesul-backend-1bde.twc1.net${item.image}`}
                alt={item.name}
              />
              <div className="order-item-info">
                <h3>{item.name}</h3>
                {discount > 0 ? (
                  <>
                    <p className="original-price">{price.toFixed(2)} сом</p>
                    <p className="discounted-price">{discountedPrice} сом</p>
                  </>
                ) : (
                  <p>{price.toFixed(2)} сом</p>
                )}
                <div className="ad_more">
                  <button
                    className="quantity-button"
                    onClick={() => handleQuantityChange(item.id, -1)}
                  >
                    -
                  </button>
                  <span className="quantity-display">{item.quantity}</span>
                  <button
                    className="quantity-button"
                    onClick={() => handleQuantityChange(item.id, 1)}
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="order-details">
        <div className="total-section">
          <h3 className="total-price">
            Итого:
            {discount > 0 ? (
              <>
                <span className="original-total-price">{calculateTotal().total} сом</span>
                <span className="discounted-total-price">{calculateTotal().discountedTotal} сом</span>
              </>
            ) : (
              `${calculateTotal().total} сом`
            )}
          </h3>
        </div>

        <div className="promo-section">
          <label htmlFor="promo-code">Промокод:</label>
          <input
            type="text"
            id="promo-code"
            value={promoCode}
            onChange={(e) => setPromoCode(e.target.value)}
          />
          <button onClick={handlePromoCodeSubmit}>Применить</button>
        </div>

        <h2>{isOrderSection ? "С собой" : "Доставка"}</h2>
        {isOrderSection ? (
          <>
            <div className="input-group">
              <label htmlFor="order-name">Имя:</label>
              <input
                type="text"
                name="name"
                value={orderDetails.name}
                onChange={handleOrderChange}
              />
            </div>
            <div className="input-group">
              <label htmlFor="order-phone">Телефон:</label>
              <input
                type="tel"
                name="phone"
                value={orderDetails.phone}
                onChange={handleOrderChange}
              />
            </div>
            <div className="input-group">
              <label htmlFor="order-comments">Комментарии:</label>
              <textarea
                name="comments"
                value={orderDetails.comments}
                onChange={handleOrderChange}
              ></textarea>
            </div>
          </>
        ) : (
          <>
            <div className="input-group">
              <label htmlFor="delivery-name">Имя:</label>
              <input
                type="text"
                name="name"
                value={deliveryDetails.name}
                onChange={handleDeliveryChange}
              />
            </div>
            <div className="input-group">
              <label htmlFor="delivery-phone">Телефон:</label>
              <input
                type="tel"
                name="phone"
                value={deliveryDetails.phone}
                onChange={handleDeliveryChange}
              />
            </div>
            <div className="input-group">
              <label htmlFor="delivery-address">Адрес:</label>
              <input
                type="text"
                name="address"
                value={deliveryDetails.address}
                onChange={handleDeliveryChange}
              />
            </div>
            <div className="input-group">
              <label htmlFor="delivery-comments">Комментарии:</label>
              <textarea
                name="comments"
                value={deliveryDetails.comments}
                onChange={handleDeliveryChange}
              ></textarea>
            </div>
          </>
        )}
        <div className="buttons">
          <button
            style={{
              borderRadius: "18px",
              fontSize: "17px",
              border: "none",
              backgroundColor: "red",
              color: "white",
              padding: "0 10px",
            }}
            onClick={() => navigate("/")}
          >
            Вернуться в меню
          </button>
          <button className="confirm-button" onClick={sendOrderToServer}>
            Подтвердить заказ
          </button>
        </div>

        {isOrderSent && (
          <div className="success-modal">
            <div className="success-modal-content">
              <div className="checkmark-circle">
                <div className="checkmark"></div>
              </div>
              <div className="success-message">
                Товар отправлен! Наши сотрудники свяжутся с вами.
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderPage;
